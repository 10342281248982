<template>
    <div class="main-ExpirePassword">
        <ui-card class="main-ExpirePassword__card mx-auto mb-5">
            <div class="text-center">
                <img class="w-50" src="https://pay.ecart.com/img/logo-negro.svg" alt="" />
            </div>
            <h5 class="main-ExpirePassword__title mb-0">Update password</h5>
            <p class="activation-info__item-text">
            <span class="fas fa-exclamation-circle"></span>
            Your password has expired please update it. 
            </p>
            <form ref="formExpirePassword" @submit.prevent="submit">
            <form-password ref="formPassword" show-password-confirmation />
            <ui-button
                    :loading="loading"
                    class="w-100"
                    type="submit"
                    variant="primary"
                >
                    Update
                </ui-button>
            </form>
        </ui-card>
        <p class="main-ExpirePassword__copy">
            &copy; 2022 EcartPay. All rights reserved.
        </p>
        <img class="main-ExpirePassword__hero" src="@/assets/img/login/hero.png" alt="Ecart Pay" />
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';

import UiButton from '@/components/ui/buttons/Button';
import UiCard from '@/components/ui/Card';
import UiInput from '@/components/ui/Input';
import FormPassword from '@/components/forms/FormPassword.vue';

export default {
    components: {
        UiButton,
        UiCard,
        UiInput,
        FormPassword
    },
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            formData: {
                email: '',
                password: '',
            },
            loading: false
        };
    },
    validations () {
        return {    
            formData: {
                email: { required, email, $autoDirty: true },
                password: {$autoDirty: true },
            },
        };
    },
    methods: {
        async submit() {
            const formPassword = this.$refs['formPassword']
            const validated = await formPassword.validate();

            if (!validated) return
            try {
                this.loading = true;
                await this.$axios.put('/_/admins/me', {
                    password: formPassword.formData.password,
                });

                await this.$store.dispatch('accounts/getAccount')
                this.$toast.success('Password updated successfully.');
                this.$router.push('/accounts');
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                this.loading = false;
            }
        }
    }
}
</script>

<style lang="scss">
.main-ExpirePassword {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    padding: 3rem 0;
    position: relative;

    @media(max-width: 576px) {
        background-color: #ffffff;
        box-shadow: none;
    }

    &__card {
        width: 100%;
        max-width: 480px;
        position: relative;
        z-index: 2;

        @media(max-width: 576px) {
            background-color: transparent;
            box-shadow: none;
        }
    }

    &__title {
        color: $general-black;
        font-weight: 500;
        font-size: 16px;
    }

    &__copy {
        color: rgba($general-black, 0.7);
        position: relative;
        z-index: 2;
    }

    &__hero {
        bottom: 0;
        height: auto;
        position: absolute;
        right: 0;
        width: 35vw;
        z-index: 1;

        @media(max-width: 576px) {
            display: none;
        }
    }
}
</style>